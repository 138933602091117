.skeleton {
    /* variables scoped here are not asseccible
       to any element without a .skeleton parent element
    */
    --skeleton-title-height: 20px;
    --skeleton-title-width: 60%;
    --skeleton-text-height: 12px;
    --skeleton-text-width: 90%;
    --skelton-text-half: 50%;
    --skeleton-avatar-height: 70px;
    --skeleton-avatar-width: 70px;
    --skeleton-thumbnail-height: 180px;
    --skeleton-thumbnail-width: 100%;
    --skeleton-div-height: 300px;
    --skeleton-div-width: 100%;
  
    background: #ddd;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 4px;
  }
  .skeleton-wrapper {
    position: relative;
    overflow: hidden;
  }
  
  .skeleton.title {
    height: var(--skeleton-title-height);
    width: var(--skeleton-title-width);
    margin-bottom: 1rem;
  }
  .skeleton.div {
    height: var(--skeleton-div-height);
    width: var(--skeleton-div-width);
    margin-bottom: 1rem;
  }
  .skeleton.text {
    height: var(--skeleton-text-height);
    width: var(--skeleton-text-width);
  }
  .skeleton.avatar {
    height: var(--skeleton-avatar-height);
    width: var(--skeleton-avatar-width);
    border-radius: 50%;
  }
  .skeleton.thumbnail {
    height: var(--skeleton-thumbnail-height);
    width: var(--skeleton-thumbnail-width);
  }
  .skeleton.half-text {
    height: var(--skeleton-text-height);
    width: var(--skelton-text-half);
  }
  .shimmer {
    position: absolute;
    background: rgba(255, 255, 255, 0.3);
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
  }

  .skeleton-section-title {
    margin-top: 30px;
  }

  .skeleton-section-title-addetail {
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    justify-content: center;
  }

  .skeleton-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }

  .skeleton-card {
    list-style-type: none;
    width: calc(45% - 10px);
    background-color: #ffffff;
    border-radius: 8px;
    color: #0b1011;
    margin: 20px 0 70px;
    padding: 30px;
  }
  .skeleton-card-link {
    color: inherit;
    text-decoration: none;
  }
  .skeleton-card-image {
    height: auto;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }
  .skeleton-card-title {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .skeleton-card-channel {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .skeleton-center {
    text-align: center;
    margin: 0 auto;
  }
  
  /* Tablets */
  @media (max-width: 1000px) {
    .App {
      max-width: 600px;
    }
    .skeleton-card {
      width: calc(50% - 22px);
    }
  }
  
  /* Mobiles */
  @media (max-width: 640px) {
    .App {
      max-width: 100%;
      padding: 0 15px;
    }
    .skeleton-card {
      width: 100%;
    }
  }
  